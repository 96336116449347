import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom/dist';

const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const state = useState();

  return (
    <WrappedComponent
      {...props}
      location={location}
      params={params}
      navigate={navigate}
      state={state}
      // etc...
    />
  );
};

export default withRouter;
