import React, { Component } from 'react';
import { createKey, renderCell, sortData } from '../../utils/fields';

class TableBody extends Component {
  render() {
    const { data, columns, sortColumn, onTdClick } = this.props;
    let sorted = sortData(data, columns, sortColumn);

    return (
      <tbody>
        {sorted.map((item) => (
          <tr key={item._id}>
            {columns.map((column) => (
              <td
                onClick={() => {
                  onTdClick(item);
                }}
                className='cursor-pointer align-middle'
                key={createKey(item, column)}
              >
                <span className='details-data'>{renderCell(item, column)}</span>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
