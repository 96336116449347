import React from 'react';
import withRouter from '../common/withRouter';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Form from '../common/form';
import {
  createHistory,
  editHistory,
  getHistories,
  removeHistory,
  restoreHistory,
} from '../../services/historyService';
import { toast } from 'react-toastify';
import Joi from '@hapi/joi';
import FeatherIcon from 'feather-icons-react';
import Moment from 'react-moment';

class DynamicNote extends Form {
  state = {
    data: [],
    histories: [],
    errors: {},
    RecordType: '',
    RecordId: '',
    show: false,
  };

  componentDidMount = async () => {
    this.schema = {
      Note: Joi.string().valid(),
      EditNote: Joi.string().valid(),
    };

    const search = new URLSearchParams(this.props.location.search);
    const RecordType = search.get('RecordType');
    const RecordId = search.get('RecordId');
    const ShowAll = search.get('ShowAll');

    console.log('LOAD');

    let { histories } = (await getHistories(RecordId, { RecordType, ShowAll }))
      .data;

    this.setState({ histories, RecordId, RecordType, ShowAll });
  };

  doRefresh = async () => {
    this.componentDidMount();
  };

  doSubmit = async () => {
    try {
      let { data, RecordId, RecordType } = this.state;
      data.RecordType = RecordType;
      data.LinkedRecordId = RecordId;
      this.setState(data);
      await createHistory(data);
      this.doRefresh();
    } catch (ex) {
      if (ex.response) {
        let errors = `${ex.response.status} - ${ex.response.statusText}: ${ex.response.data}`;
        this.setState({ errors });
        toast.error(errors);
      }
    }
  };

  handleEdit = async () => {
    try {
      let { data } = this.state;

      let hist = {
        _id: data._id,
        Note: data.EditNote,
        RecordType: data.RecordType,
      };

      await editHistory(hist);
      this.doRefresh();
    } catch (ex) {
      if (ex.response) {
        let errors = `${ex.response.status} - ${ex.response.statusText}: ${ex.response.data}`;
        this.setState({ errors });
        toast.error(errors);
      }
    }
    this.handleClose();
  };

  handleRemove = async (data) => {
    try {
      let hist = {
        _id: data._id,
        RecordType: data.RecordType,
      };

      await removeHistory(hist);
      this.doRefresh();
    } catch (ex) {
      if (ex.response) {
        let errors = `${ex.response.status} - ${ex.response.statusText}: ${ex.response.data}`;
        this.setState({ errors });
        toast.error(errors);
      }
    }
    this.handleClose();
  };

  handleRestore = async (data) => {
    try {
      let hist = {
        _id: data._id,
        RecordType: data.RecordType,
      };

      await restoreHistory(hist);
      this.doRefresh();
    } catch (ex) {
      if (ex.response) {
        let errors = `${ex.response.status} - ${ex.response.statusText}: ${ex.response.data}`;
        this.setState({ errors });
        toast.error(errors);
      }
    }
    this.handleClose();
  };

  handleShow = (data) => {
    this.setState({ data, show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShowAll = () => {
    const search = new URLSearchParams(this.props.location.search);
    search.set('ShowAll', 'true');
    this.props.navigate(`/viewDetail?${search}`);
    window.location.reload();
  };

  handleHideDeleted = () => {
    const search = new URLSearchParams(this.props.location.search);
    search.delete('ShowAll');
    this.props.navigate(`/viewDetail?${search}`);
    window.location.reload();
  };

  render() {
    const { data, histories, show, ShowAll } = this.state;
    return (
      <>
        <div className='card-body'>
          <h4>History</h4>

          <div className='border-bottom pb-5 mb-5'>
            {this.renderTextArea('NewNote', 'Note', '', 'Enter a new note...')}
            <Button variant='primary' onClick={this.handleSubmit}>
              Save note
            </Button>
          </div>
          <div id='HistoryList'>
            {histories.map((history) => (
              <div key={`hist${history._id}1`} className='d-flex mb-5'>
                <div key={`hist${history._id}2`} className='ms-3'>
                  <p key={`hist${history._id}3`} className='text-muted mb-2'>
                    <FeatherIcon
                      icon={history['Is System'] ? 'activity' : 'clipboard'}
                      className='nav-icon icon-xs me-2'
                    />
                    {history.Note}
                  </p>
                  <p
                    key={`hist${history._id}4`}
                    className='fs-5 mb-0 text-muted'
                  >
                    <FeatherIcon icon='' className='nav-icon icon-xs me-2' />
                    <Moment format='MMMM Do YYYY, h:mm:ss a'>
                      {history.DateUpdated}
                    </Moment>
                    {history['Is System'] === false ? (
                      <sub>
                        <br />
                        <FeatherIcon
                          icon=''
                          className='nav-icon icon-xs me-2'
                        />
                        <Link
                          to={this.props.location.search}
                          onClick={() => this.handleShow(history)}
                        >
                          Edit Note
                        </Link>
                        <span style={{ padding: '8px 10px' }}></span>
                        {history.DateArchived ? (
                          <Link
                            to={this.props.location.search}
                            onClick={() => this.handleRestore(history)}
                          >
                            Undelete Note
                          </Link>
                        ) : (
                          <Link
                            to={this.props.location.search}
                            onClick={() => this.handleRemove(history)}
                          >
                            Delete Note
                          </Link>
                        )}
                      </sub>
                    ) : (
                      ''
                    )}
                  </p>
                </div>
              </div>
            ))}
            <sub className='float-end'>
              {ShowAll ? (
                <Link to={this.props.location} onClick={this.handleHideDeleted}>
                  Hide Deleted Notes
                </Link>
              ) : (
                <Link to={this.props.location} onClick={this.handleShowAll}>
                  Show Deleted Notes
                </Link>
              )}
            </sub>
          </div>
        </div>
        <Modal size='lg' show={show} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>Edit Note</Modal.Title>
            <Button
              className='btn-close'
              onClick={this.handleClose}
              aria-label='Close'
            />
          </Modal.Header>
          <Modal.Body>
            <div id='EditNote' data-simplebar className='container-fluid'>
              <form id='EditNote-form' className='row'>
                {this.renderTextArea('EditNote', 'EditNote', '', data.Note)}
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light' onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant='primary' onClick={this.handleEdit}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withRouter(DynamicNote);
