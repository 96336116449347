import React, { useEffect } from 'react';
import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';

const Design = () => {
  const { selectedObjects, editor, onReady } = useFabricJSEditor();

  useEffect(() => {
    var line9 = new fabric.Line(
      [
        editor?.canvas.width / 2,
        0,
        editor?.canvas.width / 2,
        editor?.canvas.width,
      ],
      { stroke: 'green' }
    );
    line9.selectable = false;
    line9.evented = false;
    editor?.canvas.add(line9);

    fabric.Image.fromURL(
      'https://dcaservices.com/assets/img/dca2019.png',
      function (oImg) {
        editor?.canvas.add(oImg);
        editor?.canvas.centerObject(oImg);
      }
    );

    editor?.canvas.on('object:moving', function (options) {
      var snapZone = 15;
      var objectMiddle =
        options.target.left + options.target.getScaledWidth() / 2;
      if (
        objectMiddle > editor?.canvas.width / 2 - snapZone &&
        objectMiddle < editor?.canvas.width / 2 + snapZone
      ) {
        options.target
          .set({
            left:
              editor?.canvas.width / 2 - options.target.getScaledWidth() / 2,
          })
          .setCoords();
      }
    });
  }, [editor?.canvas]);

  return (
    <div className='container min-vh-100 d-flex justify-content-center align-items-center'>
      <div className='row'>
        <div className='col-12'>
          <FabricJSCanvas className='hat-canvas' onReady={onReady} />
        </div>
      </div>
    </div>
  );
};

export default Design;
