import React from 'react';

const Input = ({
  name,
  label,
  error,
  divClasses,
  btnLabel,
  btnVariant,
  btnOnClick,
  ...rest
}) => {
  const { required, type } = rest;
  return (
    <React.Fragment>
      {type === 'checkbox' ? (
        <div className='mb-3'>
          <div className={divClasses}>
            <input id={name} name={name} placeholder={label} {...rest} />
            <label htmlFor={name} className='form-check-label'>
              {label}
              <span className='required'>{required ? ' *' : ''}</span>
            </label>
          </div>
        </div>
      ) : (
        <div className={divClasses}>
          {typeof btnLabel !== 'undefined' ? (
            <>
              <input
                id={name}
                name={name}
                placeholder={label}
                aria-describedby={`btn-${name}`}
                {...rest}
              />
              <button
                className={`btn ${btnVariant}`}
                type='button'
                id={`btn-${name}`}
                onClick={btnOnClick}
              >
                {btnLabel}
              </button>
            </>
          ) : (
            <>
              <label htmlFor={name} className='form-label'>
                {label}
                <span className='required'>{required ? ' *' : ''}</span>
              </label>
              <input id={name} name={name} placeholder={label} {...rest} />
            </>
          )}
        </div>
      )}
      {error && <div className='alert alert-danger'>{error}</div>}
    </React.Fragment>
  );
};

export default Input;
