import http from './httpService';

const apiEndpoint = process.env.REACT_APP_API_URL + '/dynamic';
function contactUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getDynamics(params) {
  return http.get(apiEndpoint, { params });
}

export function getDynamic(dynamicId, params) {
  return http.get(contactUrl(dynamicId), { params });
}

export function createDynamic(dynamic) {
  return http.post(apiEndpoint, { data: dynamic });
}

export function editDynamic(dynamic) {
  return http.post(apiEndpoint + '/edit', { data: dynamic });
}
