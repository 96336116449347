import http from './httpService';

const apiEndpoint = process.env.REACT_APP_API_URL + '/customFields';

export function getFields(params) {
  return http.get(apiEndpoint, { params });
}

export function setFields(fields) {
  return http.post(apiEndpoint + '/update', { fields });
}

export function addField(field) {
  return http.post(apiEndpoint + '/add', { field });
}

export function editField(field) {
  return http.post(apiEndpoint + '/edit', { field });
}

export function removeField(field) {
  return http.post(apiEndpoint + '/remove', { field });
}

export function restoreField(field) {
  return http.post(apiEndpoint + '/restore', { field });
}
