import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

function init() {
  Sentry.init({
    dsn: 'https://aa5091ff2f2c4247ae0b1da52f13e22d@o424650.ingest.sentry.io/5951245',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

function log(msg) {
  Sentry.captureMessage(msg);
}

function error(error) {
  Sentry.captureException(error);
}

const logger = { init, log, error };

export default logger;
