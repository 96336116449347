import React, { Component } from 'react';
import moment from 'moment';
import Joi from '@hapi/joi';
import Input from './input';
import Files from './files';
import Select from 'react-select';
import TextArea from './textarea';

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = { abortEarly: false };
    const schema = Joi.object(this.schema);
    const { error } = schema.validate(this.state.data, options);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = (input) => {
    let { name, value } = input;
    const obj = { [name]: value };

    let schema = Joi.object({ [name]: this.schema[name] });
    const { error } = schema.validate(obj);

    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    console.log(errors);
    this.setState({ errors: errors || {} });
    if (errors) return;

    let { data } = this.state;
    for (let name in data) {
      let value = data[name];
      if (typeof value === 'object' && value !== null) {
        if (value.value) data[name] = value.value;
      }
    }

    this.setState({ data: data });
    this.doSubmit(e);
  };

  handleChange = async (input, selectData) => {
    const errors = { ...this.state.errors };
    let data = { ...this.state.data };

    if (input && input.currentTarget) {
      input = input.currentTarget;
    } else {
      const src = input;
      input = {};
      input.name = selectData.name;
      input.value = src;
    }

    if (input.type === 'checkbox') input.value = input.checked;

    let errorMsg = this.validateProperty(input);
    if (errorMsg) errors[input.name] = errorMsg;
    else delete errors[input.name];

    data[input.name] = input.value;
    if (typeof input === 'object' && input !== null && input.files) {
      let kml = await parseKML(input.files[0]);
      data[input.name] = kml;
    }

    this.setState({ data, errors });
  };

  handleDynamicChange = async (input, selectData) => {
    this.handleChange(input, selectData);
    this.doChange(input, selectData);
  };

  renderButton(label) {
    return (
      <button disabled={this.validate()} className='btn btn-primary'>
        {label}
      </button>
    );
  }

  renderSelect(key, name, label, options, required = false, styles) {
    const { data, errors } = this.state;

    return (
      <div key={`sel-${key}`}>
        <label htmlFor={name} className='form-label'>
          {label}
          <span className='required'>{required ? ' *' : ''}</span>
        </label>
        <Select
          key={key}
          className='mb-3 form-group'
          isClearable
          name={name}
          value={data[name]}
          placeholder={label}
          options={options}
          onChange={this.handleChange}
          error={errors[name]}
          label={label}
          styles={styles}
        />
        {errors[name] && (
          <div className='alert alert-danger'>{errors[name]}</div>
        )}
      </div>
    );
  }

  renderDynamicSelect(key, name, label, options, styles) {
    const { data, errors } = this.state;

    return (
      <React.Fragment>
        <label key={`lbl-${key}`} htmlFor={name} className='form-label'>
          {label}
        </label>
        <Select
          key={key}
          className='mb-3 form-group'
          isClearable
          name={name}
          defaultValue={[{ label: data[name], value: data[name] }]}
          placeholder={label}
          options={options}
          onChange={this.handleDynamicChange}
          error={errors[name]}
          label={label}
          styles={styles}
        />
        {errors[name] && (
          <div className='alert alert-danger'>{errors[name]}</div>
        )}
      </React.Fragment>
    );
  }

  renderReactMultiSelect(name, label, options, styles) {
    const { data, errors } = this.state;

    return (
      <React.Fragment>
        <Select
          key={`mulsel-` + name}
          className='form-group'
          isMulti
          name={name}
          value={data[name]}
          placeholder={label}
          options={options}
          onChange={this.handleChange}
          error={errors[name]}
          label={label}
          styles={styles}
        />
        {errors[name] && (
          <div className='alert alert-danger'>{errors[name]}</div>
        )}
      </React.Fragment>
    );
  }

  renderInput(
    key,
    name,
    label,
    placeholder,
    required = false,
    type = 'text',
    className = 'form-control',
    divClasses = 'mb-3 form-group',
    onChange,
    btnVariant = 'primary',
    btnLabel,
    btnOnClick
  ) {
    const { data, errors } = this.state;

    let value = data[name];
    if (value && type === 'Date')
      value = moment(value).utc().format('YYYY-MM-DD');

    return (
      <Input
        key={key}
        name={name}
        value={value || ''}
        label={label}
        placeholder={placeholder || label}
        className={className}
        onChange={onChange || this.handleChange}
        required={required}
        checked={value}
        type={type}
        error={errors[name]}
        divClasses={divClasses}
        btnVariant={btnVariant}
        btnLabel={btnLabel}
        btnOnClick={btnOnClick}
      />
    );
  }

  renderInputNumber(
    key,
    name,
    label,
    required = false,
    type = 'text',
    className = 'form-control',
    min = -99999999,
    max = 99999999
  ) {
    const { data, errors } = this.state;

    return (
      <Input
        key={key}
        name={name}
        value={data[name] || ''}
        label={label}
        className={className}
        onChange={this.handleChange}
        required={required}
        type={type}
        error={errors[name]}
        min={min}
        max={max}
      />
    );
  }

  renderTextArea(
    key,
    name,
    label,
    placeholder,
    required = false,
    type = 'text',
    className = 'form-control',
    divClasses = 'mb-3 form-group',
    onChange
  ) {
    const { data, errors } = this.state;

    return (
      <TextArea
        key={key}
        name={name}
        value={data[name] || ''}
        label={label}
        placeholder={placeholder || label}
        className={className}
        onChange={onChange || this.handleChange}
        required={required}
        type={type}
        error={errors[name]}
        divClasses={divClasses}
      />
    );
  }

  renderFiles(name, label, type = 'text', className = 'form-control') {
    const { errors } = this.state;

    return (
      <Files
        name={name}
        label={label}
        className={className}
        onChange={this.handleChange}
        type={type}
        error={errors[name]}
      />
    );
  }
}

async function parseKML(kml) {
  const contents = await getFileContents(kml);
  return contents;
}

function getFileContents(file) {
  return new Promise((resolve, reject) => {
    let contents = '';
    const reader = new FileReader();
    reader.onloadend = function (e) {
      contents = e.target.result;
      resolve(contents);
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsText(file);
  });
}

export default Form;
