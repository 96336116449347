import http from './httpService';

const apiEndpoint = process.env.REACT_APP_API_URL + '/users';

async function checkUser(id) {
  return await http.get(apiEndpoint + '/me', { id });
}

function register(user) {
  return http.post(apiEndpoint, {
    email: user.username,
    password: user.password,
    name: user.name,
  });
}

const userService = {
  register,
  checkUser,
};

export default userService;
