import axios from 'axios';
import auth from './authService';
import logger from './logService';

axios.interceptors.request.use(
  (config) => {
    const jwt = auth.getAuthToken();
    if (jwt) config.headers['x-auth-token'] = jwt;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    const originalConfig = error.config;

    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      logger.error('An unexpected error occurred.');
    }
    if (
      originalConfig &&
      !originalConfig.url.includes('/auth/login') &&
      error.response
    ) {
      if (
        expectedError &&
        error.response.status === 401 &&
        !originalConfig._retry
      ) {
        originalConfig._retry = true;

        try {
          await auth.refresh();
          const jwt = auth.getAuthToken();
          if (jwt) originalConfig.headers['x-auth-token'] = jwt;
          return axios(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(error);
  }
);

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

export default http;
