import React from 'react';
import TableHeader from './tableHeader';
import TableBody from './tableBody';

const Table = ({ columns, sortColumn, onSort, data, onTdClick, onTrClick }) => {
  return (
    <table className='table table-hover text-nowrap mb-0'>
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody
        columns={columns}
        data={data}
        sortColumn={sortColumn}
        onTdClick={onTdClick}
        onTrClick={onTrClick}
      />
    </table>
  );
};

export default Table;
