import React, { Component } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { UserContext } from '../hooks/UserContext';
import DynamicForm from './dynamic/dynamicForm.jsx';

class MainMenu extends Component {
  render() {
    return (
      <UserContext.Consumer>
        {({ user }) =>
          !user ? (
            ''
          ) : (
            <Navbar className='navbar-vertical navbar sidebar'>
              <div className='nav-scroller'>
                <Nav.Link className='navbar-brand' href='/'>
                  <img src='/assets/images/brand/logo/logo.png' alt='' />
                </Nav.Link>
                <Nav className='navbar-nav flex-column' id='sideNavbar'>
                  <Nav.Item>
                    <Nav.Link href='/'>
                      <FeatherIcon
                        icon='home'
                        className='nav-icon icon-xs me-2'
                      />
                      Dashboard
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <div className='navbar-heading'>Accounts</div>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      href='#!'
                      className='nav-link has-arrow  collapsed '
                      data-bs-toggle='collapse'
                      data-bs-target='#navContacts'
                      aria-expanded='false'
                      aria-controls='navContacts'
                    >
                      <FeatherIcon
                        icon='users'
                        className='nav-icon icon-xs me-2'
                      />
                      Contacts
                    </Nav.Link>

                    <div
                      id='navContacts'
                      className='collapse '
                      data-bs-parent='#sideNavbar'
                    >
                      <ul className='nav flex-column'>
                        <Nav.Item>
                          <Nav.Link className='nav-link ' href='/contacts'>
                            <FeatherIcon
                              icon='users'
                              className='nav-icon icon-xs me-2'
                            />
                            View Contacts
                          </Nav.Link>
                        </Nav.Item>
                        <DynamicForm RecordType='Contact' />
                        <Nav.Item>
                          <Nav.Link
                            className='nav-link '
                            href='/contact/import'
                          >
                            <FeatherIcon
                              icon='upload'
                              className='nav-icon icon-xs me-2'
                            />
                            Import contacts
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className='nav-link '
                            href='/contact/export'
                          >
                            <FeatherIcon
                              icon='download'
                              className='nav-icon icon-xs me-2'
                            />
                            Export
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className='nav-link '
                            href='/contact/custom'
                          >
                            <FeatherIcon
                              icon='edit'
                              className='nav-icon icon-xs me-2'
                            />
                            Custom Fields
                          </Nav.Link>
                        </Nav.Item>
                      </ul>
                    </div>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      href='#!'
                      className='nav-link has-arrow  collapsed '
                      data-bs-toggle='collapse'
                      data-bs-target='#navCompanies'
                      aria-expanded='false'
                      aria-controls='navCompanies'
                    >
                      <FeatherIcon
                        icon='briefcase'
                        className='nav-icon icon-xs me-2'
                      />
                      Companies
                    </Nav.Link>

                    <div
                      id='navCompanies'
                      className='collapse '
                      data-bs-parent='#sideNavbar'
                    >
                      <ul className='nav flex-column'>
                        <Nav.Item>
                          <Nav.Link className='nav-link ' href='/companies'>
                            <FeatherIcon
                              icon='briefcase'
                              className='nav-icon icon-xs me-2'
                            />
                            View Companies
                          </Nav.Link>
                        </Nav.Item>
                        <DynamicForm RecordType='Company' />
                        <Nav.Item>
                          <Nav.Link
                            className='nav-link '
                            href='/contact/import'
                          >
                            <FeatherIcon
                              icon='upload'
                              className='nav-icon icon-xs me-2'
                            />
                            Import
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className='nav-link '
                            href='/contact/export'
                          >
                            <FeatherIcon
                              icon='download'
                              className='nav-icon icon-xs me-2'
                            />
                            Export
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className='nav-link '
                            href='/company/custom'
                          >
                            <FeatherIcon
                              icon='edit'
                              className='nav-icon icon-xs me-2'
                            />
                            Custom Fields
                          </Nav.Link>
                        </Nav.Item>
                      </ul>
                    </div>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link className='nav-link ' href='/design'>
                      <FeatherIcon
                        icon='edit'
                        className='nav-icon icon-xs me-2'
                      />
                      Design
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      href='#!'
                      className='nav-link has-arrow  collapsed '
                      data-bs-toggle='collapse'
                      data-bs-target='#navMenuLevel'
                      aria-expanded='false'
                      aria-controls='navMenuLevel'
                    >
                      <FeatherIcon
                        icon='corner-left-down'
                        className='nav-icon icon-xs me-2'
                      />
                      Menu Level
                    </Nav.Link>
                    <div
                      id='navMenuLevel'
                      className='collapse '
                      data-bs-parent='#sideNavbar'
                    >
                      <ul className='nav flex-column'>
                        <Nav.Item>
                          <Nav.Link
                            href='#!'
                            className='nav-link has-arrow '
                            data-bs-toggle='collapse'
                            data-bs-target='#navMenuLevelSecond'
                            aria-expanded='false'
                            aria-controls='navMenuLevelSecond'
                          >
                            Two Level
                          </Nav.Link>
                          <div
                            id='navMenuLevelSecond'
                            className='collapse'
                            data-bs-parent='#navMenuLevel'
                          >
                            <ul className='nav flex-column'>
                              <Nav.Item>
                                <Nav.Link className='nav-link' href='#'>
                                  NavItem 1
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link className='nav-link' href='#'>
                                  NavItem 2
                                </Nav.Link>
                              </Nav.Item>
                            </ul>
                          </div>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            href='#!'
                            className='nav-link has-arrow  collapsed  '
                            data-bs-toggle='collapse'
                            data-bs-target='#navMenuLevelThree'
                            aria-expanded='false'
                            aria-controls='navMenuLevelThree'
                          >
                            Three Level
                          </Nav.Link>
                          <div
                            id='navMenuLevelThree'
                            className='collapse '
                            data-bs-parent='#navMenuLevel'
                          >
                            <ul className='nav flex-column'>
                              <Nav.Item>
                                <Nav.Link
                                  href='#!'
                                  className='nav-link  collapsed '
                                  data-bs-toggle='collapse'
                                  data-bs-target='#navMenuLevelThreeOne'
                                  aria-expanded='false'
                                  aria-controls='navMenuLevelThreeOne'
                                >
                                  NavItem 1
                                </Nav.Link>
                                <div
                                  id='navMenuLevelThreeOne'
                                  className='collapse collapse '
                                  data-bs-parent='#navMenuLevelThree'
                                >
                                  <ul className='nav flex-column'>
                                    <Nav.Item>
                                      <Nav.Link className='nav-link' href='#'>
                                        NavChild Item 1
                                      </Nav.Link>
                                    </Nav.Item>
                                  </ul>
                                </div>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link className='nav-link' href='#'>
                                  Nav Item 2
                                </Nav.Link>
                              </Nav.Item>
                            </ul>
                          </div>
                        </Nav.Item>
                      </ul>
                    </div>
                  </Nav.Item>
                </Nav>
              </div>
            </Navbar>
          )
        }
      </UserContext.Consumer>
    );
  }
}

export default MainMenu;
