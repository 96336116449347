import React, { Component } from 'react';
import EditCustomField from './editCustomField';

class CustomField extends Component {
  state = {
    itemStyle: {
      userSelect: 'none',
      padding: '',
      background: 'white',
      color: 'black',
      outline: '',
      outlineOffset: '',
    },
    fieldClasses: 'form-control customField',
    hoverClasses: 'd-none customField App text-dark',
  };

  setHoverStyle(isHovering) {
    let style = this.state.itemStyle;
    let fieldClasses = this.state.fieldClasses;
    let hoverClasses = this.state.hoverClasses;

    if (isHovering) {
      style.padding = '2% 2%';
      style.background = 'rgb(254,247,220)';
      style.color = 'white';
      style.outline = '2px dashed rgb(241,196,15)';
      style.outlineOffset = '-2%';
      fieldClasses = 'form-control customField d-none';
      hoverClasses = 'customField App text-dark';
    } else {
      style.padding = '';
      style.background = 'white';
      style.color = 'black';
      style.outline = '';
      style.outlineOffset = '';
      fieldClasses = 'form-control customField';
      hoverClasses = 'd-none customField App text-dark';
    }

    this.setState({ itemStyle: style, fieldClasses, hoverClasses });
  }

  getItemStyle = (defaultStyle, isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: isDragging ? '0% 2%' : defaultStyle.padding,
    background: isDragging ? 'rgb(254,247,220)' : defaultStyle.background,
    color: isDragging ? 'white' : defaultStyle.color,
    outline: isDragging ? '2px dashed rgb(241,196,15)' : defaultStyle.outline,
    outlineOffset: isDragging ? '-2%' : defaultStyle.outlineOffset,
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  doRefresh = () => {
    this.props.doRefresh();
  };

  render() {
    const { provided, snapshot, field } = this.props;
    const { itemStyle, fieldClasses, hoverClasses } = this.state;
    return (
      <div
        className='mt-3 mb-3 form-group'
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        onMouseEnter={() => this.setHoverStyle(true)}
        onMouseLeave={() => this.setHoverStyle(false)}
        onMouseDown={() => this.setHoverStyle(true)}
        onMouseUp={() => this.setHoverStyle(false)}
        style={this.getItemStyle(
          itemStyle,
          snapshot.isDragging,
          provided.draggableProps.style
        )}
      >
        <label htmlFor={field.Name} className='form-label'>
          {field.Name}
          {field.IsRequired ? <span className='required'> *</span> : ''}
        </label>
        <div
          id={field._id}
          name={field.Name}
          className={fieldClasses}
          data-placeholder=' '
          type={field.Type}
          disabled
        />
        <div id={field._id} name={field.Name} className={hoverClasses} disabled>
          <EditCustomField
            RecordType={this.props.RecordType}
            doRefresh={this.doRefresh}
            fieldData={field}
          />
        </div>
      </div>
    );
  }
}

export default CustomField;
