import React, { Component } from 'react';
import withRouter from '../common/withRouter';
import Table from '../common/table';
import { getDynamics } from '../../services/dynamicService';
import { getColumns } from '../../utils/fields';

class DynamicList extends Component {
  state = {
    data: [],
    columns: [],
    sortColumn: '',
  };

  async componentDidMount() {
    let pager,
      data = [];

    let { pagerr, dynamics } = (
      await getDynamics({ RecordType: this.props.RecordType })
    ).data;
    pager = pagerr;
    data = dynamics;

    let columns = await getColumns(this.props.RecordType);
    this.setState({ data, columns });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  doTDClick = (item) => {
    const url = `/viewDetail?RecordType=${this.props.RecordType}&RecordId=${item._id}`;
    this.props.navigate(url);
  };

  doTRClick = (item) => {
    //const url = '/contacts/' + item._id;
    // this.props.history.push(url);
  };

  render() {
    const { data, columns } = this.state;
    let header = 'Contacts';
    if (this.props.RecordType === 'Company') header = 'Companies';
    return (
      <>
        <div className='container-fluid px-6 py-4'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-12'>
              <div className='card mb-6'>
                <div
                  id='custom'
                  data-simplebar
                  className='card-header bg-white border-bottom-0 py-4'
                >
                  <h4 className='mb-0'>{header}</h4>
                </div>
                <div className='table-responsive'>
                  <Table
                    columns={columns}
                    sortColumn={this.state.sortColumn}
                    onSort={this.handleSort}
                    data={data}
                    onTdClick={this.doTDClick}
                    onTrClick={this.doTRClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(DynamicList);
