import _ from 'lodash';
import { getFields } from '../services/customFieldService';
import moment from 'moment';

export const createKey = (item, column) => {
  return item._id + (column.path || column.key);
};

export const renderCell = (item, column) => {
  if (column.content) return column.content(item);
  let cell = _.get(item, column.path);
  if (typeof cell === 'undefined' && column.altPath)
    cell = _.get(item, column.altPath);
  if (cell && column.type === 'Date')
    cell = moment(cell).utc().format('YYYY-MM-DD');

  return cell;
};

export const renderHeader = (item, column) => {
  let header = column.path || column.key;
  let cell = _.get(item, column.path);
  if (typeof cell === 'undefined' && column.altPath)
    cell = _.get(item, column.altPath);
  return cell ? header : '';
};

export const getColumns = async (RecordType) => {
  const fields = (await getFields({ RecordType })).data;

  let columns = [];
  let nameField = {},
    colFields = [];
  if (fields && fields.length > 0) {
    let i = 0;
    fields.forEach((field) => {
      field.index = i++;
      field.Type === 'Name' && field.IsDefaultField
        ? (nameField = field)
        : colFields.push(field);
    });
    let col2start = Math.floor(colFields.length / 2);
    let tempcol1 = [],
      tempcol2 = [];

    colFields.slice(0, col2start).forEach((field) => {
      if (field.FormColumn) {
        if (field.FormColumn === 2) tempcol2.push(field);
        else tempcol1.push(field);
      }
    });

    colFields.slice(col2start).forEach((field) => {
      if (field.FormColumn) {
        if (field.FormColumn === 1) tempcol1.push(field);
        else tempcol2.push(field);
      }
    });

    colFields.forEach((field) => {
      if (!field.FormColumn) {
        if (tempcol1.length <= tempcol2.length) tempcol1.push(field);
        else tempcol2.push(field);
      }
    });

    columns.push({
      path: nameField.Name,
      label: nameField.Name,
      type: nameField.Type,
      sortType: 'string',
    });

    tempcol1.forEach((f) => {
      columns.push({
        path: f.Name,
        label: f.Name,
        type: f.Type,
        sortType: 'string',
      });
    });

    tempcol2.forEach((f) => {
      columns.push({
        path: f.Name,
        label: f.Name,
        type: f.Type,
        sortType: 'string',
      });
    });
  }

  return columns;
};

export const sortData = (data, columns, sortColumn) => {
  let column = _.find(columns, (column) => {
    return column.path === sortColumn.path;
  });

  let sortType = '';
  if (column && column.sortType) sortType = column.sortType;

  return [...data].sort((a, b) => {
    const aa = a[sortColumn.path] || '',
      bb = b[sortColumn.path] || '';

    if (sortType === 'string') {
      if (sortColumn.order === 'asc') {
        if (aa.toLowerCase() < bb.toLowerCase()) return -1;
        if (aa.toLowerCase() > bb.toLowerCase()) return 1;
        return 0;
      }

      if (aa.toLowerCase() < bb.toLowerCase()) return 1;
      if (aa.toLowerCase() > bb.toLowerCase()) return -1;
      return 0;
    } else if (sortType === 'date') {
      return sortColumn.order === 'asc'
        ? new Date(aa) - new Date(bb)
        : new Date(bb) - new Date(aa);
    }

    //default && number
    return sortColumn.order === 'asc' ? aa - bb : bb - aa;
  });
};
