import http from './httpService';
import userService from './userService';
import jwtDecode from 'jwt-decode';
import { encryptedStore, encryptedRetrieve } from '../utils/storage';

const apiEndpoint = process.env.REACT_APP_API_URL + '/auth';
const userKey = 'user';

async function login(email, password) {
  const user = await (
    await http.post(apiEndpoint + '/login', { email, password })
  ).data;
  setUser(user);
}

async function logout() {
  const refreshToken = getRefreshToken();
  localStorage.removeItem(userKey);
  if (refreshToken) {
    http.post(apiEndpoint + '/logout', {
      refreshToken,
    });
  }
}

async function getCurrentUser() {
  let user = getUser();
  if (user) {
    const token = getAuthToken();
    const refreshToken = getRefreshToken();
    if (!token && refreshToken) {
      try {
        await refresh();
        user = getUser();
      } catch (err) {
        return null;
      }
    }

    const decoded = jwtDecode(token);
    //only do extra checks on expired token
    if (decoded.exp * 1000 > new Date().getTime()) return user;
    try {
      let checkId = (await userService.checkUser(user.id)).data;
      if (checkId === user.id) return user;
    } catch (err) {}
  }

  return null;
}

function getAuthToken() {
  const user = getUser();
  if (user && user.token) return user.token;
}

function getRefreshToken() {
  const user = getUser();
  if (user && user.refreshToken) return user.refreshToken;
}

async function refresh() {
  const refreshToken = getRefreshToken();
  try {
    const data = await http.post(apiEndpoint + '/refresh', { refreshToken });
    if (!data) return;
    let token = data.data.token;
    let user = getUser();
    user.token = token;
    setUser(user);
  } catch (error) {}
}

function getUser() {
  return encryptedRetrieve(userKey);
}

function setUser(user) {
  encryptedStore(userKey, user);
}

const auth = {
  login,
  logout,
  getCurrentUser,
  getAuthToken,
  refresh,
};

export default auth;
