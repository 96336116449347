import React, { Component, useState } from 'react';
import withRouter from '../common/withRouter';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import {
  getFields,
  setFields,
  restoreField,
} from '../../services/customFieldService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import NewCustomField from './customNewCustomField';
import CustomField from './customField';

class CustomPage extends Component {
  state = {
    nameField: {},
    col1: [],
    col2: [],
    data: {},
    errors: {},
    deletedFields: [],
  };

  async componentDidMount() {
    const fields = (await getFields({ RecordType: this.props.RecordType }))
      .data;
    const deletedFields = (
      await getFields({ RecordType: this.props.RecordType, ShowArchived: true })
    ).data;

    let nameField = {},
      colFields = [];
    if (fields && fields.length > 0) {
      let i = 0;
      fields.forEach((field) => {
        field.index = i++;
        field.Type === 'Name' && field.IsDefaultField
          ? (nameField = field)
          : colFields.push(field);
      });
      let col2start = Math.floor(colFields.length / 2);
      let tempcol1 = [],
        tempcol2 = [];

      colFields.slice(0, col2start).forEach((field) => {
        if (field.FormColumn) {
          if (field.FormColumn === 2) tempcol2.push(field);
          else tempcol1.push(field);
        }
      });

      colFields.slice(col2start).forEach((field) => {
        if (field.FormColumn) {
          if (field.FormColumn === 1) tempcol1.push(field);
          else tempcol2.push(field);
        }
      });

      colFields.forEach((field) => {
        if (!field.FormColumn) {
          if (tempcol1.length <= tempcol2.length) tempcol1.push(field);
          else tempcol2.push(field);
        }
      });

      this.setState(
        {
          nameField,
          col1: tempcol1,
          col2: tempcol2,
          deletedFields,
        },
        () => {
          this.updateColumnDisplay(false);
        }
      );
    }
  }

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'darkgrey' : '',
    padding: isDraggingOver ? '2%' : '',
    marginRight: isDraggingOver ? '-10px' : '',
    outline: isDraggingOver ? '2px dashed black' : '',
  });

  deleteItem = (list, index) => {
    return list.splice(index, 1)[0];
  };

  onDragEnd = (result) => {
    // This will update the left / right list of where the items are positioned
    // getting the source and destination object
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'droppable_left') {
        let tempList = this.state.col1;

        const removed = this.deleteItem(tempList, source.index);
        tempList.splice(destination.index, 0, removed);
        this.setState({ col1: tempList });
      } else {
        let tempList = this.state.col2;
        const removed = this.deleteItem(tempList, source.index);
        tempList.splice(destination.index, 0, removed);
        this.setState({ col2: tempList });
      }
    } else {
      let tempcol1 = this.state.col1;
      let tempcol2 = this.state.col2;
      if (source.droppableId === 'droppable_left') {
        const removed = this.deleteItem(tempcol1, source.index);
        tempcol2.splice(destination.index, 0, removed);
        this.setState({ col1: tempcol1, col2: tempcol2 });
      } else {
        const removed = this.deleteItem(tempcol2, source.index);
        tempcol1.splice(destination.index, 0, removed);
        this.setState({ col1: tempcol1, col2: tempcol2 });
      }
    }

    //Now take the list and save the changes on the API
    this.updateColumnDisplay(true); // save?
  };

  updateColumnDisplay = async (save) => {
    const { col1, col2 } = this.state;
    let tempcol1 = [],
      tempcol2 = [],
      fields = [];
    let order = 0;
    col1.forEach((field) => {
      field.SortOrder = order++;
      field.FormColumn = 1;
      tempcol1.push(field);
    });
    fields = [...fields, ...tempcol1];
    col2.forEach((field) => {
      field.SortOrder = order++;
      field.FormColumn = 2;
      tempcol2.push(field);
    });
    fields = [...fields, ...tempcol2];
    this.setState({ col1: tempcol1, col2: tempcol2 });

    if (save) {
      try {
        await setFields(JSON.stringify(fields));
      } catch (err) {
        toast.error(err.response.data);
      }
    }
  };

  restore = async (field) => {
    await restoreField(JSON.stringify(field));
    this.doRefresh();
  };

  doRefresh = async () => {
    this.componentDidMount();
  };

  render() {
    const { nameField, col1, col2, deletedFields } = this.state;
    return (
      <>
        <div className='container-fluid px-6 py-4'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-12'>
              <div>
                <div
                  className='border-bottom pb-4 mb-4 d-flex align-items-center
                  justify-content-between'
                >
                  <div className='mb-2 mb-lg-0'>
                    <h3 className='mb-0 fw-bold'>
                      Custom {this.props.RecordType} Fields
                    </h3>
                  </div>
                  <div>
                    <NewCustomField
                      RecordType={this.props.RecordType}
                      doRefresh={this.doRefresh}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='card mb-6'>
                <div
                  id='custom'
                  data-simplebar
                  className='card-header bg-white border-bottom-0 py-4'
                >
                  <h4 className='mb-0'>{this.props.RecordType} Fields</h4>
                </div>
                <div className='card-body'>
                  <p>
                    Click-and-drag a field to move where it will show on the
                    form. Added fields can be edited, removed, and restored.
                  </p>
                  <div id='custom-form' className='row'>
                    <div className='col-12 mt-3 mb-3 form-group'>
                      <label htmlFor='Name' className='form-label'>
                        {nameField.Name}
                        {nameField.IsRequired ? (
                          <span className='required'> *</span>
                        ) : (
                          ''
                        )}
                      </label>
                      <div
                        id={nameField.Name}
                        name={nameField.Name}
                        data-placeholder='This field is locked to this position and is always required.'
                        className='form-control customField'
                        type={nameField.Type}
                        disabled
                      />
                    </div>

                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <div className='col-6 form-group'>
                        <Droppable droppableId='droppable_left'>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              style={this.getListStyle(snapshot.isDraggingOver)}
                            >
                              {col1.map((field, i) => {
                                return (
                                  <Draggable
                                    disableInteractiveElementBlocking
                                    key={field._id}
                                    draggableId={field._id}
                                    index={i}
                                  >
                                    {(provided, snapshot) => (
                                      <CustomField
                                        RecordType={this.props.RecordType}
                                        doRefresh={this.doRefresh}
                                        field={field}
                                        provided={provided}
                                        snapshot={snapshot}
                                      />
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                      <div className='col-6 form-group'>
                        <Droppable droppableId='droppable_right'>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              style={this.getListStyle(snapshot.isDraggingOver)}
                            >
                              {col2.map((field, i) => {
                                return (
                                  <Draggable
                                    key={field._id}
                                    draggableId={field._id}
                                    index={i}
                                  >
                                    {(provided, snapshot) => (
                                      <CustomField
                                        RecordType={this.props.RecordType}
                                        doRefresh={this.doRefresh}
                                        field={field}
                                        provided={provided}
                                        snapshot={snapshot}
                                      />
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </DragDropContext>
                  </div>
                </div>
              </div>
              {deletedFields && deletedFields.length > 0 ? (
                <div className='card mb-6'>
                  <div
                    id='custom'
                    data-simplebar
                    className='card-header bg-white border-bottom-0 py-4'
                  >
                    <h4 className='mb-0'>Deleted Fields</h4>
                  </div>
                  <div className='card-body'>
                    {deletedFields.map((field, i) => {
                      return (
                        <div key={`d ${field._id}`}>
                          <h5 key={`h ${field._id}`}>{field.Name}</h5>
                          <p key={`p ${field._id}`}>
                            {field.Type} field deleted{' '}
                            <Moment format='YYYY-MM-DD'>
                              {field.DateArchived}
                            </Moment>
                            {' - '}
                            <Link
                              key={`Link ${field._id}`}
                              to='#'
                              onClick={() => this.restore(field)}
                            >
                              restore
                            </Link>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(CustomPage);
