import React, { Component } from 'react';
import withRouter from '../common/withRouter';
import { getDynamic } from '../../services/dynamicService';
import { getColumns } from '../../utils/fields';
import { createKey, renderCell, renderHeader } from '../../utils/fields';
import DynamicNote from './dynamicNote';
import DynamicForm from './dynamicForm';

class DynamicDetail extends Component {
  state = {
    data: [],
    columns: [],
    RecordType: '',
    RecordId: '',
  };

  async componentDidMount() {
    let data = [];
    const search = new URLSearchParams(this.props.location.search);
    const RecordType = search.get('RecordType');
    const RecordId = search.get('RecordId');

    let { dynamic } = (await getDynamic(RecordId, { RecordType })).data;
    data = dynamic;

    let columns = await getColumns(RecordType);
    this.setState({ data, columns, RecordType, RecordId });
  }

  doRefresh = async () => {
    window.location.reload();
  };

  render() {
    const { columns, data, RecordType, RecordId } = this.state;
    return (
      <>
        <div className='container-fluid p-6'>
          <div className='row'>
            <div className='row mt-6'>
              <div className='col-xl-6 col-md-12 col-12 mb-6'>
                <div className='card mb-4'>
                  <div className='card-body'>
                    <h4 className='card-title'>Attachments</h4>
                    <p>{data._id}</p>
                  </div>
                </div>
                <div className='card mb-4'>
                  <DynamicNote />
                </div>
              </div>
              <div className='col-xl-6 col-md-12 col-12 mb-6'>
                <div className='card mb-4'>
                  <div className='card-body'>
                    <h4 className='card-title'>
                      Information
                      <DynamicForm
                        doRefresh={this.doRefresh}
                        RecordType={RecordType}
                        RecordId={RecordId}
                        IsEdit={true}
                      />
                    </h4>
                    <>
                      {columns.map((column) => (
                        <div className='mb-5' key={createKey(data, column)}>
                          <h6 className='fs-5 ls-2'>
                            {renderHeader(data, column)}
                          </h6>
                          <p className='mb-0'>{renderCell(data, column)}</p>
                        </div>
                      ))}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(DynamicDetail);
