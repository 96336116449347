import React from 'react';
import Joi from '@hapi/joi';
import withRouter from './common/withRouter';
import Form from './common/form';
import auth from '../services/authService';
import { Navigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { UserContext } from './../hooks/UserContext';

class LoginForm extends Form {
  state = {
    data: { email: '', password: '' },
    errors: {},
  };

  schema = {
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('Email'),
    password: Joi.string().required().label('Password'),
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      await auth.login(data.email, data.password);

      const { state } = this.props.location;
      window.location = state ? state.from.pathname : '/';
      console.log(window.location);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <UserContext.Consumer>
        {({ user }) =>
          user ? (
            <Navigate to='/' />
          ) : (
            <div className='container d-flex flex-column'>
              <div className='row align-items-center justify-content-center g-0 min-vh-100'>
                <div className='col-12 col-md-8 col-lg-6 col-xxl-4 py-8 py-xl-0'>
                  <div className='card smooth-shadow-md'>
                    <div className='card-body p-6'>
                      <div className='mb-4'>
                        <img
                          src='../assets/images/brand/logo/logo.png'
                          className='mb-2'
                          alt=''
                        />
                        <p className='mb-6'>
                          Please enter your user information.
                        </p>
                      </div>

                      <form onSubmit={this.handleSubmit}>
                        <div className='mb-3'>
                          {this.renderInput(
                            'email',
                            'email',
                            'Username or email',
                            'Email address here'
                          )}
                        </div>

                        <div className='mb-3'>
                          {this.renderInput(
                            'password',
                            'password',
                            'Password',
                            '************'
                          )}
                        </div>

                        <div className='d-lg-flex justify-content-between align-items-center mb-4'>
                          {this.renderInput(
                            'rememberme',
                            'rememberme',
                            'Remember me',
                            'Remember me',
                            false,
                            'checkbox',
                            'form-check-input',
                            'form-check custom-checkbox'
                          )}
                        </div>
                        <div>
                          <div className='d-grid'>
                            <Button variant='primary' type='submit'>
                              Sign in
                            </Button>
                          </div>

                          <div className='d-md-flex justify-content-between mt-4'>
                            <div className='mb-2 mb-md-0'>
                              <a href='sign-up.html' className='fs-5'>
                                Create An Account
                              </a>
                            </div>
                            <div>
                              <a
                                href='forget-password.html'
                                className='text-inherit
                        fs-5'
                              >
                                Forgot your password?
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </UserContext.Consumer>
    );
  }
}

export default withRouter(LoginForm);
