import React from 'react';
import withRouter from '../common/withRouter';
import { Button, Modal } from 'react-bootstrap';
import Form from '../common/form';
import { addField } from '../../services/customFieldService';
import { toast } from 'react-toastify';
import Joi from '@hapi/joi';
import { lowerCase } from 'lodash';

const FieldTypes = {
  Address: 'Address',
  Date: 'Date',
  Dropdown: 'Dropdown',
  Email: 'Email',
  FileLink: 'FileLink',
  Name: 'Name',
  Number: 'Number',
  Phone: 'Phone',
  Text: 'Text',
  TextArea: 'TextArea',
  Website: 'Website',
};

class NewCustomField extends Form {
  state = {
    show: false,
    data: {},
    errors: {},
    customOptions: [],
    nextCustomOptionId: 4,
    showSubOptions: false,
  };

  componentDidMount = () => {
    this.schema = {
      'Field name': Joi.string().valid(),
      'Field type': Joi.object(),
      required: Joi.boolean(),
    };
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
    this.props.doRefresh();
  };

  doSubmit = async () => {
    try {
      let { data, customOptions } = this.state;
      //this.setState(data);

      let field = JSON.stringify({
        Name: data['Field name'],
        RecordType: this.props.RecordType,
        Type: data['Field type'],
        IsRequired: data.required,
        Options: customOptions,
      });

      await addField(field);
    } catch (ex) {
      if (ex.response) {
        let errors = `${ex.response.status} - ${ex.response.statusText}: ${ex.response.data}`;
        this.setState({ errors });
        toast.error(errors);
      }
    }
    this.handleClose();
  };

  addClick = () => {
    this.setState((prevState) => ({
      customOptions: [
        ...prevState.customOptions,
        { Name: 'Option ' + prevState.nextCustomOptionId, Option: '' },
      ],
      nextCustomOptionId: prevState.nextCustomOptionId + 1,
    }));
  };

  removeClick = (ident) => {
    let { data, customOptions } = this.state;
    // let idx = customOptions.findIndex((co) => co.Name === ident);
    customOptions = customOptions.filter((co) => co.Name !== ident);
    delete data[ident];
    this.setState({ data, customOptions });
  };

  subChange = async (input, selectData) => {
    const errors = { ...this.state.errors };
    let data = { ...this.state.data };
    let customOptions = [...this.state.customOptions];

    if (input && input.currentTarget) {
      input = input.currentTarget;
    } else {
      const src = input;
      input = {};
      input.name = selectData.name;
      input.value = src;
    }

    let errorMsg = this.validateProperty(input);
    if (errorMsg) errors[input.name] = errorMsg;
    else delete errors[input.name];

    let idx = customOptions.findIndex((co) => co.Name === input.name);
    customOptions[idx].Option = input.value;
    data[input.name] = input.value;
    this.setState({ data, customOptions, errors });
  };

  doChange = async (input, selectData) => {
    let { customOptions } = this.state;
    if (
      input &&
      input.value &&
      input.value === 'Dropdown' &&
      customOptions.length === 0
    ) {
      this.setState({
        showSubOptions: true,
        customOptions: [
          ...this.state.customOptions,
          { Name: 'Option 1', Option: '' },
          { Name: 'Option 2', Option: '' },
          { Name: 'Option 3', Option: '' },
        ],
      });
    } else {
      this.setState({ showSubOptions: false, customOptions: [] });
    }
  };

  renderSubOptions() {
    let { showSubOptions } = this.state;
    return (
      <div className='mb-3'>
        {this.state.customOptions.map((opt, i) => {
          if (opt) {
            let id = parseInt(i) + 1;
            let ident = 'Option ' + id;
            this.schema[ident] = Joi.string().valid();
            return this.renderInput(
              'opt' + id,
              ident,
              ident,
              ident,
              false,
              'Text',
              'form-control',
              'mb-3 form-group input-group',
              this.subChange,
              'btn-outline-secondary',
              'Remove',
              this.removeClick.bind(this, ident)
            );
          }
        })}
        {showSubOptions ? (
          <Button variant='primary' onClick={this.addClick.bind(this)}>
            Add More
          </Button>
        ) : (
          ''
        )}
      </div>
    );
  }

  render() {
    let { show } = this.state;
    let options = [];
    for (let key in FieldTypes) {
      let field = {};
      field.label = key;
      field.value = FieldTypes[key];
      options.push(field);
    }

    return (
      <>
        <Button variant='primary' onClick={this.handleShow}>
          Create New Field
        </Button>
        <Modal size='lg' show={show} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>
              Create custom {lowerCase(this.props.RecordType)} field
            </Modal.Title>
            <Button
              className='btn-close'
              onClick={this.handleClose}
              aria-label='Close'
            />
          </Modal.Header>
          <Modal.Body>
            <div id='NewCustomField' data-simplebar className='container-fluid'>
              <form
                id='NewCustomField-form'
                onSubmit={this.handleSubmit}
                className='row'
              >
                {this.renderInput(
                  'Field name 1',
                  'Field name',
                  'Field name',
                  ' ',
                  false,
                  'Text'
                )}
                {this.renderDynamicSelect(
                  'Dynam 1',
                  'Field type',
                  'Field type',
                  options
                )}
                {this.renderSubOptions()}
                {this.renderInput(
                  'required',
                  'required',
                  'Make this field required',
                  'Make this field required',
                  false,
                  'checkbox',
                  'form-check-input',
                  'form-check custom-checkbox'
                )}
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light' onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant='primary' onClick={this.handleSubmit}>
              Create New Field
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withRouter(NewCustomField);
