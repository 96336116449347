import CryptoJS from 'crypto-js';

const secret = 'gyn9day8YPJ-gmd9ceu';

export function encryptedStore(key, data) {
  localStorage.setItem(
    key,
    CryptoJS.AES.encrypt(JSON.stringify(data), secret).toString()
  );
}

export function encryptedRetrieve(key) {
  let data = localStorage.getItem(key);
  if (data) {
    var bytes = CryptoJS.AES.decrypt(data, secret);
    var decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decrypted;
  }

  return null;
}
