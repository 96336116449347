import React from 'react';

const Files = ({ name, label, error, ...rest }) => {
  return (
    <div className='form-group files'>
      <label htmlFor={name} className='form-label'>
        {label}
      </label>
      <input id={name} name={name} placeholder={label} {...rest} />
      {error && <div className='alert alert-danger'>{error}</div>}
    </div>
  );
};

export default Files;
