import React, { Component } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Home from './components/home';
import NotFound from './components/notFound';
import MainMenu from './components/mainMenu';
import NavBarTop from './components/navBarTop';
import LoginForm from './components/loginForm';
import Logout from './components/logout';
import auth from './services/authService';
import { UserContext } from './hooks/UserContext';
import ProtectedRoute from './components/common/protectedRoute';
import CustomPage from './components/custom/pageCustom';
import DynamicList from './components/dynamic/dynamicList';
import DynamicDetail from './components/dynamic/dynamicDetail';
import { encryptedStore, encryptedRetrieve } from './utils/storage';
import Design from './components/design';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const Protected = ({ children }) => {
  let { user } = this.state;
  console.log(user);
  console.log('TEST');
  return user ? children : <Navigate to='/login' replace />;
};

class App extends Component {
  state = { user: null, loading: true };

  toggleNavBar = (show) => {
    const { user } = this.state;
    if (user) {
      encryptedStore('nav', { showNavBar: show });
      this.setState({ showNavBar: show });
    }
  };

  async componentDidMount() {
    let navState = encryptedRetrieve('nav');
    await auth.getCurrentUser().then((user) => {
      let { showNavBar } = this.state;
      if (navState)
        showNavBar = showNavBar
          ? showNavBar
          : user
          ? navState.showNavBar
          : false;
      else showNavBar = user ? true : false;

      this.setState({ user: user, showNavBar, loading: false });
    });
  }

  render() {
    const { loading, user, showNavBar } = this.state;
    let userData = user ? user : null;
    return loading ? (
      'Loading...'
    ) : (
      <React.Fragment>
        <UserContext.Provider value={{ user: userData }}>
          <ToastContainer />
          <div id='db-wrapper' className={showNavBar ? '' : 'toggled'}>
            <MainMenu />
            <div id='page-content'>
              <NavBarTop
                toggleNavBar={this.toggleNavBar}
                showNavBar={showNavBar}
              />
              <main>
                <Routes>
                  <Route path='/login' element={<LoginForm />} />
                  <Route path='/logout' element={<Logout />} />
                  <Route element={<ProtectedRoute />}>
                    <Route path='/home' element={<Home />} />
                    <Route
                      path='/contact/custom'
                      element={<CustomPage RecordType='Contact' />}
                    />
                    <Route
                      path='/contacts'
                      element={<DynamicList RecordType='Contact' />}
                    />
                    <Route
                      path='/company/custom'
                      element={<CustomPage RecordType='Company' />}
                    />
                    <Route
                      path='/companies'
                      element={<DynamicList RecordType='Company' />}
                    />
                    <Route path='/viewDetail' element={<DynamicDetail />} />
                    <Route
                      path='/design'
                      element={<Design RecordType='Company' />}
                    />
                  </Route>
                  <Route path='/404' component={NotFound} />
                  <Route exact path='/' element={<Navigate to='/home' />} />
                  <Route path='/404' />
                </Routes>
              </main>
            </div>
          </div>
        </UserContext.Provider>
      </React.Fragment>
    );
  }
}

export default App;
