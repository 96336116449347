import React, { Component } from 'react';
import withRouter from './common/withRouter';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { UserContext } from './../hooks/UserContext';

class NavBarTop extends Component {
  componentDidMount() {
    let { showNavBar } = this.props;
    this.props.toggleNavBar(showNavBar);
  }

  handleMenuClick = (e) => {
    e.preventDefault();
    let { showNavBar } = this.props;
    showNavBar = !showNavBar;
    this.props.toggleNavBar(showNavBar);
  };

  render() {
    return (
      <UserContext.Consumer>
        {({ user }) =>
          !user ? (
            ''
          ) : (
            <>
              <div className='header @@classList'>
                <Nav className='navbar-classic navbar navbar-expand-lg'>
                  <Link to='#' onClick={(e) => this.handleMenuClick(e)}>
                    <FeatherIcon
                      icon='menu'
                      className='nav-icon me-2 icon-xs'
                    />
                  </Link>
                  <div className='ms-lg-3 d-none d-md-none d-lg-block'>
                    <form className='d-flex align-items-center'>
                      <input
                        type='search'
                        className='form-control'
                        placeholder='Search'
                      />
                    </form>
                  </div>

                  <Nav className='navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap'>
                    <li className='dropdown stopevent'>
                      <Nav.Link
                        href='#!'
                        className='btn btn-light btn-icon rounded-circle indicator indicator-primary text-muted'
                        role='button'
                        id='dropdownNotification'
                        data-bs-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        <FeatherIcon icon='bell' className='icon-xs' />
                      </Nav.Link>
                      <div
                        className='dropdown-menu dropdown-menu-lg dropdown-menu-end'
                        aria-labelledby='dropdownNotification'
                      >
                        <div className=''>
                          <div
                            className='border-bottom px-3 pt-2 pb-3 d-flex
           justify-content-between align-items-center'
                          >
                            <p className='mb-0 text-dark fw-medium fs-4'>
                              Notifications
                            </p>
                            <Nav.Link className='text-muted' href='#'>
                              <span>
                                <FeatherIcon
                                  icon='settings'
                                  className='me-1 icon-xxs'
                                />
                              </span>
                            </Nav.Link>
                          </div>

                          <ul className='list-group list-group-flush notification-list-scroll'>
                            <li className='list-group-item bg-light'>
                              <Nav.Link className='text-muted' href='#'>
                                <h5 className='fw-bold mb-1'>Rishi Chopra</h5>
                                <p className='mb-0'>
                                  Mauris blandit erat id nunc blandit, ac
                                  eleifend dolor pretium.
                                </p>
                              </Nav.Link>
                            </li>
                            <li className='list-group-item'>
                              <Nav.Link className='text-muted' href='#'>
                                <h5 className='fw-bold mb-1'>Neha Kannned</h5>
                                <p className='mb-0'>
                                  Proin at elit vel est condimentum elementum id
                                  in ante. Maecenas et sapien metus.
                                </p>
                              </Nav.Link>
                            </li>
                            <li className='list-group-item'>
                              <Nav.Link className='text-muted' href='#'>
                                <h5 className='fw-bold mb-1'>
                                  Nirmala Chauhan
                                </h5>
                                <p className='mb-0'>
                                  Morbi maximus urna lobortis elit sollicitudin
                                  sollicitudieget elit vel pretium.
                                </p>
                              </Nav.Link>
                            </li>
                            <li className='list-group-item'>
                              <Nav.Link className='text-muted' href='#'>
                                <h5 className='fw-bold mb-1'>Sina Ray</h5>
                                <p className='mb-0'>
                                  Sed aliquam augue sit amet mauris volutpat
                                  hendrerit sed nunc eu diam.
                                </p>
                              </Nav.Link>
                            </li>
                          </ul>
                          <div className='border-top px-3 py-2 text-center'>
                            <Nav.Link
                              className='text-inherit fw-semi-bold'
                              href='#'
                            >
                              View all Notifications
                            </Nav.Link>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className='dropdown ms-2'>
                      <Nav.Link
                        href='#!'
                        className='rounded-circle'
                        role='button'
                        id='dropdownUser'
                        data-bs-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        <div className='avatar avatar-md avatar-indicators avatar-online'>
                          <img
                            alt='avatar'
                            src='/assets/images/avatar/avatar-1.jpg'
                            className='rounded-circle'
                          />
                        </div>
                      </Nav.Link>
                      <div
                        className='dropdown-menu dropdown-menu-end'
                        aria-labelledby='dropdownUser'
                      >
                        <div className='px-4 pb-0 pt-2'>
                          <div className='lh-1 '>
                            <h5 className='mb-1'> John E. Grainger</h5>
                            <Nav.Link className='text-inherit fs-6' href='#'>
                              View my profile
                            </Nav.Link>
                          </div>
                          <div className=' dropdown-divider mt-3 mb-2'></div>
                        </div>

                        <ul className='list-unstyled'>
                          <li>
                            <Nav.Link className='dropdown-item' href='#'>
                              <FeatherIcon
                                icon='user'
                                className='me-2 icon-xxs dropdown-item-icon'
                              />
                              Edit Profile
                            </Nav.Link>
                          </li>
                          <li>
                            <Nav.Link className='dropdown-item' href='#'>
                              <FeatherIcon
                                icon='activity'
                                className='me-2 icon-xxs dropdown-item-icon'
                              />
                              Activity Log
                            </Nav.Link>
                          </li>

                          <li>
                            <Nav.Link
                              className='dropdown-item text-primary'
                              href='#'
                            >
                              <FeatherIcon
                                icon='star'
                                className='me-2 icon-xxs text-primary dropdown-item-icon'
                              />
                              Go Pro
                            </Nav.Link>
                          </li>
                          <li>
                            <Nav.Link className='dropdown-item' href='#'>
                              <FeatherIcon
                                icon='settings'
                                className='me-2 icon-xxs dropdown-item-icon'
                              />
                              Account Settings
                            </Nav.Link>
                          </li>
                          <li>
                            <Nav.Link className='dropdown-item' href='/logout'>
                              <FeatherIcon
                                icon='power'
                                className='me-2 icon-xxs dropdown-item-icon'
                              />
                              Sign Out
                            </Nav.Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </Nav>
                </Nav>
              </div>
            </>
          )
        }
      </UserContext.Consumer>
    );
  }
}

export default withRouter(NavBarTop);
