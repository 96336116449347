import React from 'react';

const NotFound = () => {
  return (
    <div className='container min-vh-100 d-flex justify-content-center align-items-center'>
      <div className='row'>
        <div className='col-12'>
          <div className='text-center'>
            <div className='mb-3'>
              <img
                src='../assets/images/error/404-error-img.png'
                alt=''
                className='img-fluid'
              />
            </div>
            <h1 className='display-4 fw-bold'>Oops! the page not found.</h1>
            <a href='/' className='btn btn-primary'>
              Go Home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
