import http from './httpService';

const apiEndpoint = process.env.REACT_APP_API_URL + '/history';
function historyUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function createHistory(history) {
  return http.post(apiEndpoint, { data: history });
}

export function editHistory(history) {
  return http.post(apiEndpoint + '/edit', { data: history });
}

export function getAllHistories(params) {
  return http.get(apiEndpoint, { params });
}

export function getHistories(historyId, params) {
  return http.get(`${apiEndpoint}/dynamic/${historyId}`, { params });
}

export function getHistory(historyId) {
  return http.get(historyUrl(historyId));
}

export function removeHistory(history) {
  return http.post(apiEndpoint + '/remove', { data: history });
}

export function restoreHistory(history) {
  return http.post(apiEndpoint + '/restore', { data: history });
}
