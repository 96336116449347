import Joi from '@hapi/joi';

export const dynamicSchema = (fields) => {
  let schema = {};
  fields.forEach((field) => {
    let joiType = Joi;
    switch (field.Type) {
      case 'Name':
        joiType = Joi.string().valid();
        break;
      case 'Email':
        joiType = Joi.string().email({ tlds: { allow: false } });
        break;
      case 'Phone':
        joiType = Joi.string()
          .trim()
          .regex(/^[0-9]{7,10}$/);
        break;
      case 'Address':
        joiType = Joi.string().valid();
        break;
      case 'Date':
        joiType = Joi.alternatives([Joi.date(), Joi.string().valid('')]);
        break;
      case 'Dropdown':
        joiType = Joi.any().valid();
        break;
      case 'Text':
      default:
        joiType = Joi.string().valid();
        break;
      case 'TextArea':
        joiType = Joi.string().valid();
        break;
      case 'Website':
        joiType = Joi.string().uri().valid();
        break;
    }

    joiType = field.IsRequired
      ? joiType.required()
      : joiType.optional().allow(null, '');
    schema[field.Name] = joiType;
  });

  return schema;
};
