import React from 'react';
import withRouter from '../common/withRouter';
import { Button, Modal, Nav } from 'react-bootstrap';
import _ from 'lodash';

import Form from '../common/form';
import { getFields } from '../../services/customFieldService';
import { dynamicSchema } from '../../models/dynamic';
import { createDynamic, editDynamic } from '../../services/dynamicService';
import { getDynamic } from '../../services/dynamicService';
import { getColumns } from '../../utils/fields';
import { toast } from 'react-toastify';
import FeatherIcon from 'feather-icons-react';

class DynamicForm extends Form {
  state = { fields: [], show: false, data: {}, errors: {} };

  async componentDidMount() {
    let fields = [];
    if (this.props.RecordType) {
      fields = (await getFields({ RecordType: this.props.RecordType })).data;
      this.schema = dynamicSchema(fields);
      this.setState({ fields });
    }

    if (this.props.RecordId && this.props.IsEdit) {
      let data = [];
      const RecordType = this.props.RecordType;
      const RecordId = this.props.RecordId;

      let { dynamic } = (await getDynamic(RecordId, { RecordType })).data;
      //CLEAR OUT INFO IN EDITS THAT IS DONE BY BACKEND..._ID will be RECORDID
      fields.forEach((f) => {
        data[f.Name] = _.get(dynamic, f.Name);
      });

      let columns = await getColumns(RecordType);
      this.setState({ data, columns, RecordType, RecordId });
    }
  }

  handleShow = async () => {
    await this.componentDidMount();
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  doRefresh = () => {
    this.props.doRefresh();
  };

  doSubmit = async () => {
    try {
      let { data } = this.state;
      data.RecordType = this.props.RecordType;
      this.setState(data);
      if (this.props.IsEdit) {
        data._id = this.props.RecordId;
        await editDynamic(data);
      } else {
        await createDynamic(data);
      }
      document.getElementById('dynamicform-form').reset();
    } catch (ex) {
      if (ex.response) {
        let errors = `${ex.response.status} - ${ex.response.statusText}: ${ex.response.data}`;
        this.setState({ errors });
        toast.error(errors);
      }
    }
    this.handleClose();
    this.doRefresh();
  };

  render() {
    let { show, fields } = this.state;
    let nameField = {},
      colFields = [];
    if (fields && fields.length > 0) {
      fields.forEach((field) => {
        field.Type === 'Name' && field.IsDefaultField
          ? (nameField = field)
          : colFields.push(field);
      });
    }

    let col2start = Math.floor(colFields.length / 2);
    let col1 = [],
      col2 = [];

    colFields.slice(0, col2start).forEach((field) => {
      if (field.FormColumn) {
        if (field.FormColumn === 2) col2.push(field);
        else col1.push(field);
      }
    });

    colFields.slice(col2start).forEach((field) => {
      if (field.FormColumn) {
        if (field.FormColumn === 1) col1.push(field);
        else col2.push(field);
      }
    });

    colFields.forEach((field) => {
      if (!field.FormColumn) {
        if (col1.length <= col2.length) col1.push(field);
        else col2.push(field);
      }
    });

    let icon =
      this.props.RecordType === 'Contact' ? 'user-plus' : 'plus-square';

    let IsEdit = this.props.IsEdit;
    let float = IsEdit ? 'float-end' : '';

    return (
      <Nav.Item className={float}>
        <Nav.Link className='nav-link ' onClick={this.handleShow}>
          {IsEdit ? (
            <sub>Edit</sub>
          ) : (
            <>
              <FeatherIcon icon={icon} className='nav-icon icon-xs me-2' />
              New {this.props.RecordType}
            </>
          )}
        </Nav.Link>

        <Modal size='lg' show={show} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>
              {IsEdit ? 'Edit' : 'Add a'} {this.props.RecordType}
            </Modal.Title>
            <Button
              className='btn-close'
              onClick={this.handleClose}
              aria-label='Close'
            />
          </Modal.Header>
          <Modal.Body>
            <div id='dynamicform' data-simplebar className='container-fluid'>
              <form
                id='dynamicform-form'
                onSubmit={this.handleSubmit}
                className='row'
              >
                {this.renderInput(
                  nameField._id,
                  nameField.Name,
                  nameField.Name,
                  ' ',
                  nameField.IsRequired,
                  nameField.Type
                )}
                <div className='col-6'>
                  {col1.map((field, i) => {
                    if (field.Type === 'Dropdown') {
                      let options = [];
                      for (let key in field.Options) {
                        let fld = {};
                        fld.label = field.Options[key].Option;
                        fld.value = field.Options[key].Option;
                        options.push(fld);
                      }

                      return this.renderSelect(
                        field._id,
                        field.Name,
                        field.Name,
                        options,
                        field.IsRequired
                      );
                    }

                    if (field.Type === 'TextArea') {
                      return this.renderTextArea(
                        field._id,
                        field.Name,
                        field.Name,
                        ' ',
                        field.IsRequired,
                        field.Type
                      );
                    }

                    return this.renderInput(
                      field._id,
                      field.Name,
                      field.Name,
                      ' ',
                      field.IsRequired,
                      field.Type
                    );
                  })}
                </div>
                <div className='col-6'>
                  {col2.map((field) => {
                    if (field.Type === 'Dropdown') {
                      let options = [];
                      for (let key in field.Options) {
                        let fld = {};
                        fld.label = field.Options[key].Option;
                        fld.value = field.Options[key].Option;
                        options.push(fld);
                      }

                      return this.renderSelect(
                        field._id,
                        field.Name,
                        field.Name,
                        options
                      );
                    }

                    if (field.Type === 'TextArea') {
                      return this.renderTextArea(
                        field._id,
                        field.Name,
                        field.Name,
                        ' ',
                        field.IsRequired,
                        field.Type
                      );
                    }

                    return this.renderInput(
                      field._id,
                      field.Name,
                      field.Name,
                      ' ',
                      field.IsRequired,
                      field.Type
                    );
                  })}
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light' onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant='primary' onClick={this.handleSubmit}>
              {IsEdit ? 'Edit' : 'Create'} {this.props.RecordType}
            </Button>
          </Modal.Footer>
        </Modal>
      </Nav.Item>
    );
  }
}

export default withRouter(DynamicForm);
