import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../../hooks/UserContext';
import { useLocation } from 'react-router-dom/dist';

const useAuth = () => {
  return React.useContext(UserContext);
};

const ProtectedRoute = () => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    return <Navigate to='/login' state={{ from: location }} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
